import HttpClient from 'shared/domain/delivery/HttpClient';
import PrintOrderReturnReferenceFailedError from 'core/domain/model/printOrderReturnReference/write/PrintOrderReturnReferenceFailedError';
import OrderReturnReferenceWriter from 'core/domain/model/printOrderReturnReference/write/OrderReturnReferenceWriter';
import PrintLine from 'shared/domain/model/PrintLine';
import { OrderReturnReference } from 'core/domain/model/printOrderReturnReference/write/OrderReturnReference';

class HttpOrderReturnReferenceWriter implements OrderReturnReferenceWriter {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  public printOrderReturnReferenceLabel(reference: OrderReturnReference, printLine: PrintLine): Promise<void> {
    const body = { product_variant_id: reference.id, printer_line: printLine.lineToNumber() };
    return this.httpClient.post<void>('/api/print-product-variant-label', body).catch(() => {
      throw new PrintOrderReturnReferenceFailedError();
    });
  }
}

export default HttpOrderReturnReferenceWriter;
