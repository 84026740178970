import axios, { AxiosError, AxiosInstance } from 'axios';
import qs from 'qs';
import HttpClient, { HttpClientResponseError, ResponseType } from 'shared/domain/delivery/HttpClient';

class AxiosHttpClient implements HttpClient {
  readonly instance: AxiosInstance;

  public constructor(baseURL = '') {
    this.instance = axios.create({
      baseURL,
    });
  }

  public async get<T>(
    endpoint: string,
    queryParams?: Record<string, unknown>,
    responseType: ResponseType = 'json',
  ): Promise<T> {
    return this.instance
      .get<T>(endpoint, {
        responseType,
        params: queryParams,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'comma' });
        },
      })
      .then((response) => response.data)
      .catch((error: AxiosError<Record<string, unknown>>) => {
        throw this.toHttpClientResponseError('GET', endpoint, error);
      });
  }

  public async post<T>(
    endpoint: string,
    body: Record<string, unknown>,
    responseType: ResponseType = 'json',
  ): Promise<T> {
    return this.instance
      .post<T>(endpoint, body, { responseType })
      .then((response) => response.data)
      .catch((error: AxiosError<Record<string, unknown>>) => {
        throw this.toHttpClientResponseError('POST', endpoint, error);
      });
  }

  public async put<T>(
    endpoint: string,
    body: Record<string, unknown>,
    responseType: ResponseType = 'json',
  ): Promise<T> {
    return this.instance
      .put<T>(endpoint, body, { responseType })
      .then((response) => response.data)
      .catch((error: AxiosError<Record<string, unknown>>) => {
        throw this.toHttpClientResponseError('PUT', endpoint, error);
      });
  }

  public async patch<T>(
    endpoint: string,
    body: Record<string, unknown>,
    responseType: ResponseType = 'json',
  ): Promise<T> {
    return this.instance
      .patch<T>(endpoint, body, { responseType })
      .then((response) => response.data)
      .catch((error: AxiosError<Record<string, unknown>>) => {
        throw this.toHttpClientResponseError('PATCH', endpoint, error);
      });
  }

  public async delete<T>(
    endpoint: string,
    body: Record<string, unknown>,
    responseType: ResponseType = 'json',
  ): Promise<T> {
    return this.instance
      .delete<T>(endpoint, { data: body, responseType })
      .then((response) => response.data)
      .catch((error: AxiosError<Record<string, unknown>>) => {
        throw this.toHttpClientResponseError('DELETE', endpoint, error);
      });
  }

  private toHttpClientResponseError(
    method: string,
    url: string,
    error: AxiosError<Record<string, unknown>>,
  ): HttpClientResponseError {
    const responseError = error.response
      ? {
          status: error.response.status,
          body: error.response.data,
        }
      : {};

    return {
      method,
      url,
      message: error.message,
      ...responseError,
    };
  }
}

export default AxiosHttpClient;
