import React, { useEffect, useMemo } from 'react';
import REACT_ROUTES from './routes';
import AuthenticationMiddleware from './AuthenticationMiddleware';
import { Outlet, generatePath, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import App from 'ui/views/app/App';
import useIsEmployeeLoggedIn from 'ui/views/_behaviors/useIsEmployeeLoggedIn';

const Login = React.lazy(() => import('ui/views/login/Login'));
const Route = React.lazy(() => import('ui/views/route/Route'));
const Shipping = React.lazy(() => import('ui/views/shipping/Shipping'));
const Inventory = React.lazy(() => import('ui/views/inventory/Inventory'));
const Verification = React.lazy(() => import('ui/views/verification/Verification'));
const Picking = React.lazy(() => import('ui/views/picking/Picking'));
const PickingRoute = React.lazy(() => import('ui/views/unsold/pickingRoute/PickingRoute'));
const Returns = React.lazy(() => import('ui/views/returns/Returns'));
const Unsold = React.lazy(() => import('ui/views/unsold/Unsold'));
const Locations = React.lazy(() => import('ui/views/locations/Locations'));

const Routing: React.FC = () => {
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();

  const isEmployeeStillLoggedIn = useIsEmployeeLoggedIn();
  const isLoginView = useMemo(() => currentPath === REACT_ROUTES.LOGIN, [currentPath]);

  useEffect(() => {
    if (currentPath === REACT_ROUTES.ROUTES) {
      navigate(generatePath(`/${REACT_ROUTES.PICKING}`), { replace: true });
    }
    if (isEmployeeStillLoggedIn && isLoginView) {
      navigate(generatePath(`/${REACT_ROUTES.PICKING}`), { replace: true });
    }
  }, [currentPath, navigate, isEmployeeStillLoggedIn, isLoginView]);

  const routes = useMemo(
    () => [
      {
        path: REACT_ROUTES.LOGIN,
        element: isEmployeeStillLoggedIn === false ? <Login /> : null,
      },
      {
        path: '',
        element: (
          <AuthenticationMiddleware>
            <App>
              <Outlet />
            </App>
          </AuthenticationMiddleware>
        ),
        children: [
          {
            path: REACT_ROUTES.ROUTES,
            element: <Route />,
          },
          {
            path: REACT_ROUTES.SHIPPING,
            element: <Shipping />,
          },
          {
            path: REACT_ROUTES.INVENTORY,
            element: <Inventory />,
          },
          {
            path: REACT_ROUTES.VERIFICATION,
            element: <Verification />,
          },
          {
            path: REACT_ROUTES.PICKING,
            element: <Picking />,
          },
          {
            path: REACT_ROUTES.RETURNS,
            element: <Returns />,
          },
          {
            path: REACT_ROUTES.UNSOLD,
            element: <Unsold />,
          },
          {
            path: REACT_ROUTES.UNSOLD_PICKING_ROUTE,
            element: <PickingRoute />,
          },
          {
            path: REACT_ROUTES.LOCATIONS,
            element: <Locations />,
          },
        ],
      },
    ],
    [isEmployeeStillLoggedIn],
  );
  return useRoutes(routes);
};

export default Routing;
