import { DomainEvent } from '@lookiero/messaging.js';
import Location from 'core/domain/model/location/write/Location';

class LocationUpdated extends DomainEvent {
  readonly location: Location;

  public constructor(location: Location) {
    super();
    this.location = location;
  }

  /* istanbul ignore next */
  public messageName(): string {
    return 'LocationUpdated';
  }
}

export default LocationUpdated;
