import { QueryHandler } from '@lookiero/messaging.js';

import LocationReader from '../../../domain/model/location/write/LocationReader';
import { Location } from '../../../domain/model/location/read/Location';
import GetLocations from './GetLocations';

class GetLocationsHandler implements QueryHandler<GetLocations> {
  private readonly repository: LocationReader;

  public constructor(repository: LocationReader) {
    this.repository = repository;
  }

  public async handle(query: GetLocations): Promise<Location | undefined> {
    return this.repository.getLocations(query.locationCode, query.pageNumber);
  }
}

export default GetLocationsHandler;
