import { CommandHandler, EventBus } from '@lookiero/messaging.js';
import LocationWriter from '../../../domain/model/location/write/LocationWriter';
import Location from '../../../domain/model/location/write/Location';
import DeleteLocation from './DeleteLocation';

class DeleteLocationHandler implements CommandHandler<DeleteLocation> {
  private readonly locationWriter: LocationWriter;
  eventBus!: EventBus;

  public constructor(locationWriter: LocationWriter) {
    this.locationWriter = locationWriter;
  }

  public async execute(command: DeleteLocation): Promise<void> {
    const newLocation = Location.removeLocation(command.location);
    await this.locationWriter.removeLocation(newLocation);
    this.eventBus.publish([...newLocation.recordedEvents()]);
  }
}

export default DeleteLocationHandler;
