import { Command } from '@lookiero/messaging.js';
import { ILocation } from '../../../domain/model/location/write/Location';

class DeleteLocation extends Command {
  public readonly location: ILocation;

  public constructor(location: ILocation) {
    super();
    this.location = location;
  }

  /* istanbul ignore next */
  protected messageName(): string {
    return 'DeleteLocation';
  }
}

export default DeleteLocation;
