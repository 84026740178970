import AggregateRoot from 'shared/domain/AggregateRoot';
import LocationCreated from './event/LocationCreated';
import LocationUpdated from './event/LocationUpdated';
import LocationRemoved from './event/LocationRemoved';

export interface ILocation {
  readonly id?: string;
  readonly locationCode: string;
  readonly purpose: string;
  readonly segment: string;
  readonly priority: number;
}

class Location extends AggregateRoot {
  readonly location: ILocation;

  private constructor(location: ILocation) {
    super();
    this.location = location;
  }

  public static createLocation(location: ILocation): Location {
    const newLocation = new Location(location);
    newLocation.record(new LocationCreated(newLocation));
    return newLocation;
  }

  public static updateLocation(location: ILocation): Location {
    const newLocation = new Location(location);
    newLocation.record(new LocationUpdated(newLocation));
    return newLocation;
  }

  public static removeLocation(location: ILocation): Location {
    const newLocation = new Location(location);
    newLocation.record(new LocationRemoved(newLocation));
    return newLocation;
  }
}

export default Location;
