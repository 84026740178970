import HttpClient from 'shared/domain/delivery/HttpClient';
import LocationReader from '../../../domain/model/location/write/LocationReader';
import { Location } from '../../../domain/model/location/read/Location';

class HttpLocationReader implements LocationReader {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  public getLocations(locationCode: string, pageNumber: number): Promise<Location | undefined> {
    const queryParams = {
      locationCodePrefix: locationCode || '',
      pageNumber: pageNumber - 1,
    };
    return this.httpClient.get<Location>('/locations/v1/list', queryParams);
  }
}

export default HttpLocationReader;
