enum REACT_ROUTES {
  ROUTES = 'routes',
  LOGIN = '/login',
  PICKING = 'picking',
  SHIPPING = 'shipping',
  INVENTORY = 'inventory',
  VERIFICATION = 'verification',
  RETURNS = 'returns',
  UNSOLD = 'unsold',
  UNSOLD_PICKING_ROUTE = 'unsold-picking/:id',
  LOCATIONS = 'locations',
}

export default REACT_ROUTES;
