import { Query } from '@lookiero/messaging.js';

class GetLocations extends Query {
  public readonly locationCode: string;
  public readonly pageNumber: number;

  public constructor(locationCode: string, pageNumber: number) {
    super();
    this.locationCode = locationCode;
    this.pageNumber = pageNumber;
  }
  /* istanbul ignore next */
  protected messageName(): string {
    return 'GetLocations';
  }
}

export default GetLocations;
