import { Query, QueryHandler } from '@lookiero/messaging.js';
import Class from '@lookiero/messaging.js/dist/Class';
import { Container } from 'inversify';
import { registers } from '../helpers/registers';
import { handlerContainer } from '../helpers/handlerContainer';

import FindCouriers from 'core/application/courier/findCouriers/FindCouriers';
import CheckCurrentEmployeeIsLoggedIn from 'core/application/employee/checkCurrentEmployeeIsLoggedIn/CheckCurrentEmployeeIsLoggedIn';
import GetCurrentEmployee from 'core/application/employee/getCurrentEmployee/GetCurrentEmployee';
import FindInventory from 'core/application/inventory/findInventory/FindInventory';
import ListNotifications from 'core/application/notification/listNotifications/ListNotifications';
import FindOrder from 'core/application/order/FindOrder';
import GetOrdersWithPublicOrderId from 'core/application/order/getOrdersWithPublicOrderId/GetOrdersWithPublicOrderId';
import GetUnsoldOrders from 'core/application/order/getUnsoldOrders/GetUnsoldOrders';
import FindOrderReturn from 'core/application/orderReturn/findOrderReturn/FindOrderReturn';
import FindPallet from 'core/application/pallet/findPallet/FindPallet';

import FindPrinters from 'core/application/printer/findPrinters/FindPrinters';
import FindRouteById from 'core/application/route/findRouteById/FindRouteById';
import FindRoutes from 'core/application/route/findRoutes/FindRoutes';
import FindRoutesCount from 'core/application/route/findRoutesCount/FindRoutesCount';
import FindRouteWithPreparedTrolley from 'core/application/route/findRouteWithPreparedTrolley/FindRouteWithPreparedTrolley';
import GetPreparerRoute from 'core/application/route/getPreparerRoute/GetPreparerRoute';
import GetRouteItem from 'core/application/routeItem/getRouteItem/GetRouteItem';
import { queryRegisterList } from './queryRegisterList';
import FindFinishedPallets from 'core/application/pallet/findFinishedPallets/FindFinishedPallets';
import FindDraftPallets from 'core/application/pallet/findDraftPallets/FindDraftPallets';
// Locations
import GetLocations from 'core/application/location/getLocations/GetLocations';

interface QueryDependeciesFunctionArgs {
  readonly container: Container;
}
type QueryDependeciesFunctionResult = [Class<Query>, QueryHandler<Query>][];
interface QueryDependeciesFunction {
  (args: QueryDependeciesFunctionArgs): Promise<QueryDependeciesFunctionResult>;
}

const queryDependecies: QueryDependeciesFunction = async ({ container }) => {
  const { listRegister } = registers({ container });
  const handler = handlerContainer({ container });

  listRegister(queryRegisterList);

  return Promise.resolve([
    [FindRoutes, handler('FindRoutesHandler')],
    [FindRoutesCount, handler('FindRoutesCountHandler')],
    [GetPreparerRoute, handler('GetPreparerRouteHandler')],
    [CheckCurrentEmployeeIsLoggedIn, handler('CheckCurrentEmployeeIsLoggedInHandler')],
    [GetCurrentEmployee, handler('GetCurrentEmployeeHandler')],
    [ListNotifications, handler('ListNotificationsHandler')],
    [FindPallet, handler('FindPalletHandler')],
    [FindFinishedPallets, handler('FindFinishedPalletsHandler')],
    [FindDraftPallets, handler('FindDraftPalletsHandler')],
    [FindCouriers, handler('FindCouriersHandler')],
    [FindInventory, handler('FindInventoryHandler')],
    [FindOrderReturn, handler('FindOrderReturnHandler')],
    [FindOrder, handler('FindOrderHandler')],
    [FindPrinters, handler('FindPrintersHandler')],
    [FindRouteWithPreparedTrolley, handler('FindRouteWithPreparedTrolleyHandler')],
    [GetOrdersWithPublicOrderId, handler('GetOrdersWithPublicOrderIdHandler')],
    [GetUnsoldOrders, handler('GetUnsoldOrdersHandler')],
    [FindRouteById, handler('FindRouteByIdHandler')],
    [GetRouteItem, handler('GetRouteItemHandler')],
    [GetLocations, handler('GetLocationsHandler')],
  ] as QueryDependeciesFunctionResult);
};

export { queryDependecies };
