import HttpClient from 'shared/domain/delivery/HttpClient';
import LocationWriter from '../../../domain/model/location/write/LocationWriter';
import Location from '../../../domain/model/location/write/Location';

class HttpLocationWriter implements LocationWriter {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  public createLocation(location: Location): Promise<void> {
    const body = {
      ...location.location,
    };
    return this.httpClient.post<void>('/locations/v1/create', body);
  }

  public updateLocation(location: Location): Promise<void> {
    const body = {
      ...location.location,
    };
    return this.httpClient.put<void>('/locations/v1/update', body);
  }

  public removeLocation(location: Location): Promise<void> {
    const body = { id: location.location.id };
    return this.httpClient.delete<void>('/locations/v1/delete', body);
  }
}

export default HttpLocationWriter;
